<template>
  <div class="w-100">
    <h1 class="mb-4">Create meta data</h1>

    <div class="form">
      <label for="">Type:</label>
      <div class="form-row">
        <div class="form-group col-lg-6">
          <select
            v-model="subjectType"
            @change="loadSubjects()"
            class="form-control"
          >
            <option
              v-for="type in subjectTypes"
              :value="type"
              v-bind:key="type"
            >
              {{ type.title }}
            </option>
          </select>
        </div>
      </div>

<!--      <div v-if="subjectType !== null && subjectType.title !== 'products'">-->
<!--        <label for="">Select Item:</label>-->
<!--        <div class="form-row">-->
<!--          <div class="form-group col-lg-6">-->
<!--            <select v-model="subjectId" class="form-control">-->
<!--              <option-->
<!--                  v-for="subject in subjects"-->
<!--                  :value="subject.id"-->
<!--                  v-bind:key="subject.id"-->
<!--              >-->
<!--                {{ subject.title }}-->
<!--              </option>-->
<!--            </select>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

        <label class="typo__label" for="ajax">Search for Item</label>
        <div class="form-row">
          <div class="form-group col-lg-6">
            <multiselect v-model="selectedSubject" id="ajax" label="title" track-by="id" placeholder="Type to search"
                         open-direction="bottom" :options="subjects" :multiple="false" :searchable="true" :loading="isLoading"
                         :internal-search="false" :clear-on-select="false" :close-on-select="true" :options-limit="300"
                         :limit="3" :max-height="600" :show-no-results="false" :hide-selected="true"
                         @search-change="asyncFind">
              <template #tag="{ option, remove }"><span class="custom__tag"><span>{{ option.name }}</span><span
                  class="custom__remove" @click="remove(option)">❌</span></span></template>
              <template #clear="props">
                <div class="multiselect__clear" v-if="selectedSubject.length"
                     @mousedown.prevent.stop="clearAll(props.search)"></div>
              </template>
              <template #noResult>
                <span>Oops! No elements found. Consider changing the search query.</span>
              </template>
            </multiselect>
          </div>
        </div>

      <div v-if="this.subjectId != null" class="mb-4">
        <button class="btn btn-primary" @click ="generateMetaData">Generate metadata with AI</button>

        <div v-if="metaDataLoading">Generating metadata with chat gpt...</div>
      </div>



      <label for="">Meta title:</label>
      <div class="form-row">

        <div class="form-group col-lg-12">
          <input
            v-model="form.title"
            class="form-control"
            placeholder="Title"
          />
        </div>
      </div>

      <label for="">Meta description:</label>
      <div class="form-row">
        <div class="form-group col-lg-12">
          <input
            v-model="form.description"
            class="form-control"
            placeholder="Description"
          />
        </div>
      </div>

      <ul style="padding-left: 20px; margin-top: 20px">
        <li v-for="error in errors" v-bind:key="error" class="text-danger">
          {{ error }}
        </li>
      </ul>

      <button type="submit" class="btn btn-success pl-4 pr-4" @click="submit()">
        Create
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { config } from "../../../helpers";
import metadataOptions from "@/static/metadata.json"
import Multiselect from 'vue-multiselect'
export default {
  name: "MetaDataCreate",
  components: {
    Multiselect
  },
  data() {
    return {
      searchable: true,
      subjectTypes: metadataOptions,

      subjects: [],

      experiences: [],
      selectedSubject: '',
      isLoading: false,

      subjectType: null,
      subjectId: null,
      metaDataLoading: false,

      form: {
        title: null,
        description: null,
      },
      errors: [],
    };
  },

  watch: {
    selectedSubject: function (val) {
      this.subjectId = val.id;
    },
  },

  methods: {
    asyncFind (query) {
      this.isLoading = true

      this.subjects = [];
      if(this.subjectType.value !== 0) {
        if (this.subjectType.extra_data) {
          this.subjects.push(this.subjectType.extra_data);
        }
      }

      let myData = {
        filters: {
          "title": query
        }
      };
      axios
          .get(`${config("BACKEND_ROOT")}/admin/${this.subjectType.title}`, { params: myData })
          .then((response) => {
            this.subjects =[...this.subjects,...response.data.data];
            this.isLoading = false
          })
          .catch((err) => {
          });
    },
    clearAll () {
      this.selectedSubject = ''
    },

    loadSubjects() {
      this.subjects = [];
      if(this.subjectType.value != 0){
        if(this.subjectType.extra_data){
          this.subjects.push(this.subjectType.extra_data);
        }

        if(this.subjectType.title !== 'products'){
          this.searchable = false
          axios
              .get(`${config("BACKEND_ROOT")}/admin/${this.subjectType.title}`)
              .then((response) => {
                this.subjects =[...this.subjects,...response.data.data];
                this.isLoading = false
              })
              .catch((err) => {
              });
        }

      }
      else{
        this.subjects = [];
        this.subjects.push({'id' : this.subjectType.value, 'title': this.subjectType.title});
      }
    },

    async generateMetaData() {
      this.metaDataLoading = true;

      var type = this.subjectType.title;
      if (this.subjectType.title === 'home'){
        var id = 0;
      }else{
        id = this.subjectId;
      }

      axios.post(`${config("BACKEND_ROOT")}/admin/meta-data/${type}/${id}/ai`).then((response) => {
        var res = response.data.data;
        this.form.title = res.title;
        this.form.description = res.description;
        this.metaDataLoading = false;
      }).catch((error) => {
        console.error('Error:', error);
      });
    },

    submit() {
      axios
        .post(
          `${config("BACKEND_ROOT")}/admin/meta-data/${this.subjectType.title}/${
            this.subjectId
          }`,
          this.form
        )
        .then((response) => {
          this.$router.push("/meta-data");
        })
        .catch((error) => {
          this.errors = Object.values(error.response.data.errors).flat();
        });
    },
  },
};
</script>
