<template>
  <div class="w-100">
    <h1>Carts List</h1>

    <div class="row mt-4">
      <div class="col-lg-3 mb-3" style="margin-top: 1px;">
        <label>Search by user id, name, or cart id:</label>
        <input
          placeholder="Search..."
          v-model="inputFilters.q"
          class="form-control"
          v-on:keyup.enter="filter"
        />
      </div>
      <div class="col-lg-3">
        <label for="date-datepicker">Last update date:</label>
        <date-picker
          v-model="inputFilters.date"
          id="date-datepicker"
          locale="en"
          format="YYYY-MM-DD"
          range
          clearable
          color="#f42f4b"
        />
      </div>
      <div class="col-lg-2">
        <label for="status">Cart status:</label>
        <select v-model="inputFilters.status" id="status" class="form-control">
          <option value="1">Open</option>
          <option value="0">Closed</option>
        </select>
      </div>
      <div class="col-lg-2">
        <label for="status">Buyer type:</label>
        <select v-model="inputFilters.buyer_type" id="buyer_type" class="form-control">
          <option value="customer">Customer</option>
          <option value="admin">Admin</option>
        </select>
      </div>
      <div class="col-lg-2">
        <label class="d-block" style="visibility: hidden">Filter</label>
        <button class="btn btn-primary" @click="filter">
          <i class="fas fa-search"></i>
        </button>
      </div>
    </div>

    <div class="table-responsive">
      <table class="table mt-2">
        <thead class="thead-light">
          <tr>
            <th scope="col">Cart ID</th>
            <th scope="col">Customer ID</th>
            <th scope="col">Customer Name</th>
            <th scope="col">Item Count</th>
            <th scope="col">Payable</th>
            <th scope="col">Last Update</th>
            <th scope="col">Buyer</th>
            <th scope="col">Action log</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          <router-link tag="tr" :to="{ name: 'cart.show', params: {id: cart.id}}" v-for="cart in items" v-bind:key="cart.id" style="cursor:pointer;">
            <td>{{ cart.id }}</td>
            <td>{{ cart.user.id }}</td>
            <td>{{ cart.user.first_name + " " + cart.user.last_name }}</td>
            <td>{{ cart.items.length }}</td>
            <td v-if="cart.meta">AED {{ cart?.meta['payable'] }}</td>
            <td>{{ formatDate(cart.last_update) }}</td>
            <td v-if="cart.meta" v-text="cart?.meta?.buyer_type || '-'"></td>
            <td v-text="cart.last_action"></td>
            <td v-html="getStatusBadge(cart.status)"></td>
          </router-link>
        </tbody>
      </table>
    </div>

    <nav>
      <ul class="pagination">
        <b-pagination v-model="page" :total-rows="totalItems" :per-page="perPage" last-number></b-pagination>
      </ul>
    </nav>
  </div>
</template>

<script>
import { config } from "../helpers";

export default {
  name: "Carts",
  data() {
    return {
      url: "",
      items: [],
      inputFilters: {
        q: null,
        status: null,
        buyer_type: null,
        date: [],
      },
      isFiltered: false,
      filters: {},
      page: 1,
      lastPage: 1,
      totalItems: 1,
      perPage: 1,
    };
  },

  mounted() {
    this.url = config("BACKEND_ROOT") + "/admin/carts";
    this.loadData();
  },

  methods: {
    loadData() {
      let myData = { page: this.page, sort_by: "updated_at" };

      if (this.isFiltered) {
        myData = { ...myData, q: this.filters.q, date: this.filters.date, status: this.filters.status, buyer_type: this.filters.buyer_type };
      }

      axios.get(this.url, { params: myData }).then((response) => {
        this.items = response.data.data;
        this.page = response.data.meta.current_page;
        this.lastPage = response.data.meta.last_page;
        this.totalItems = response.data.meta.total;
        this.perPage = response.data.meta.per_page;
      });
    },

    filter() {
      this.page = 1;
      this.isFiltered = true;
      let filters = {};

      if (this.inputFilters.q) {
        filters.q = this.inputFilters.q;
      }

      if (this.inputFilters.date[0]) {
        filters.date = {};
        filters.date.start = this.inputFilters.date[0];
        filters.date.end = this.inputFilters.date[1];
      }

      if (this.inputFilters.status) {
        filters.status = this.inputFilters.status;
      }

      if (this.inputFilters.buyer_type) {
        filters.buyer_type = this.inputFilters.buyer_type;
      }

      this.filters = filters;

      this.loadData();
    },

    formatDate(date) {
      if (! date) return null;

      date = new Date(date);
      
      return (
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1) +
        "-" +
        date.getDate() +
        " " +
        date.getHours() +
        ":" +
        date.getMinutes()
      );
    },

    getStatusBadge(isClosed) {
      if (!isClosed) {
        return '<span class="badge badge-success">Purchased</span>';
      } else {
        return '<span class="badge badge-warning">Open</span>';
      }
    },
  },

  watch: {
    page: function (val) {
      this.loadData();
    },
  },
};
</script>

<style scoped>
.table {
  font-size: 14px !important;
}
</style>