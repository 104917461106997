<template>
  <div>
    <div class="w-100" v-if="loaded">
      <div id="admin-panel">
        <div id="sidebar">
          <div class="sidebar__logo-wrapper">
            <div class="sidebar__logo"></div>
          </div>
          <div class="sidebar__menu">
            <ul>
              <router-link to="/">Dashboard</router-link>
              <router-link to="/company-info">Company-info</router-link>
              <router-link to="/orders">Booked List</router-link>
              <router-link to="/gifts">Gift List</router-link>
              <router-link to="/carts">Carts List</router-link>
              <router-link to="/invoices">Invoices List</router-link>
              <router-link to="/users">Customers List</router-link>
              <router-link to="/categories">Categories Management</router-link>
              <router-link to="/promotions">PromotionCodes List</router-link>

              <a
                href.prevent="#"
                v-b-toggle="'sidebar-menu1'"
                class="cursor-pointer"
                >Corporate Management
                <b-badge pill variant="danger" v-if="corporateMessagesCount">{{
                  corporateMessagesCount
                }}</b-badge>
                <i class="fa fa-angle-down"></i>
              </a>
              <b-collapse id="sidebar-menu1">
                <router-link to="/corporate-quote-list" class="ml-3"
                  >Quote List
                  <b-badge
                    pill
                    variant="danger"
                    v-if="corporateMessagesCount"
                    >{{ corporateMessagesCount }}</b-badge
                  ></router-link
                >
                <router-link to="/corporates" class="ml-3"
                  >Corporates</router-link
                >
              </b-collapse>

              <a
                href.prevent="#"
                v-b-toggle="''"
                class="cursor-pointer"
                >Reports <i class="fa fa-angle-down"></i
              ></a>
              <b-collapse id=""> </b-collapse>

              <router-link to="/reviews">Review Management</router-link>

              <a
                href.prevent="#"
                v-b-toggle="'sidebar-menu2'"
                class="font-weight-bold mt-4 cursor-pointer"
                >Experiences Management <i class="fa fa-angle-down"></i
              ></a>
              <b-collapse id="sidebar-menu2">
                <router-link to="/experiences" class="ml-3"
                  >Experience List</router-link
                >
                <router-link to="/collections" class="ml-3"
                  >Collections List</router-link
                >
                <router-link to="/tags" class="ml-3">Tags Management</router-link>
                <router-link to="/blackout-dates" class="ml-3"
                  >Blackout Dates</router-link
                >
              </b-collapse>

              <a
                href.prevent="#"
                v-b-toggle="'sidebar-menu3'"
                class="font-weight-bold mt-4 cursor-pointer"
                >Marketing Management <i class="fa fa-angle-down"></i
              ></a>
              <b-collapse id="sidebar-menu3">
                <router-link to="/banners">Banners</router-link>
                <router-link to="/landing-pages">Landing Pages</router-link>
              </b-collapse>

              <a
                  href.prevent="#"
                  v-b-toggle="'sidebar-menu4'"
                  class="font-weight-bold mt-4 cursor-pointer"
              >SEO Settings <i class="fa fa-angle-down"></i
              ></a>
              <b-collapse id="sidebar-menu4">
                <router-link to="/redirects">Redirects</router-link>
                <router-link to="/meta-data">Meta Data</router-link>
              </b-collapse>
              <router-link to="/searches">Searches on site</router-link>
            </ul>
          </div>
        </div>

        <div class="admin-panel__content">
          <div class="admin-panel__content__header">
            <div class="float-left text-left">
              <b-breadcrumb
                :items="$route.matched[0].meta.breadcrumb"
              ></b-breadcrumb>
            </div>
            <div class="float-right w-50 pt-2 text-right">
              <div
                class="
                  admin-panel__content__header__config
                  mr-3
                  pt-1
                  pb-1
                  d-inline-block
                "
              >
                <i class="fa fa-cog"></i>
              </div>
              <div
                class="
                  admin-panel__content__header__messages
                  mr-3
                  pt-1
                  pb-1
                  d-inline-block
                  position-relative
                  cursor-pointer
                "
                aria-controls="messagesCollapse"
                :aria-expanded="messagesCollapseVisible ? 'true' : 'false'"
                @click="messagesCollapseVisible = !messagesCollapseVisible"
              >
                <b-collapse
                  id="messagesCollapse"
                  v-model="messagesCollapseVisible"
                  class="position-absolute"
                  v-if="bootData.messages.length"
                >
                  <div
                    v-for="message in bootData.messages"
                    v-bind:key="message.id"
                    class="messagesCollapse__item"
                  >
                    <span class="messagesCollapse__item__type">{{
                      message.form_title == "corporate quote list"
                        ? "Corporate"
                        : message.form_title
                    }}</span>
                    {{ formatDate(message.created_at) }}
                  </div>
                </b-collapse>
                <i class="fa fa-envelope"></i>
                <b-badge variant="danger" v-if="bootData.messages.length">{{
                  bootData.messages.length
                }}</b-badge>
              </div>
              <b-dropdown
                :text="
                  'Hello, ' +
                  (this.bootData.user.first_name ||
                    this.bootData.user.email.split('@')[0]) +
                  '!'
                "
                variant="outline-secondary"
                size="sm"
                class="float-right d-inline"
              >
                <b-dropdown-item
                  ><i class="fa fa-wallet mr-1"></i>
                  {{ this.bootData.user.balance + " AED" }}</b-dropdown-item
                >
                <b-dropdown-item href="#" @click.prevent="logout()"
                  >Log out</b-dropdown-item
                >
              </b-dropdown>
            </div>
          </div>

          <div class="pt-2 pr-4 pl-4">
            <router-view />
          </div>
        </div>
      </div>
    </div>

    <div class="loading-logo" v-if="!loaded"></div>
  </div>
</template>

<script>
import { config } from "../helpers";

export default {
  data: () => ({
    bootData: null,
    loaded: false,

    messagesCollapseVisible: false,

    drawer: null,
  }),

  props: {
    source: String,
  },

  mounted() {
    this.loadBootData();
  },

  methods: {
    loadBootData() {
      axios.get(`${config("BACKEND_ROOT")}/admin/boot`).then((response) => {
        this.bootData = response.data;
        this.loaded = true;
      });
    },

    logout() {
      if (confirm("Are you sure?")) {
        let refreshTokenId = $cookies.get("dd-refresh-token").split("|", 1);
        axios
          .post(`${config("BACKEND_ROOT")}/auth/logout/${refreshTokenId}`)
          .then((response) => {
            $cookies.remove("dd-access-token");
            $cookies.remove("dd-refresh-token");
            this.$router.push(`/login`);
          });
      }
    },

    formatDate(date) {
      if (!date) return null;

      date = new Date(date);

      return (
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1) +
        "-" +
        date.getDate() +
        " " +
        date.getHours() +
        ":" +
        date.getMinutes()
      );
    },
  },

  computed: {
    corporateMessagesCount() {
      if (!this.bootData.messages) {
        return 0;
      }

      return this.bootData.messages.filter(
        (el) => el.form_title == "corporate quote list"
      ).length;
    },
  },
};
</script>

<style>
.loading-logo {
  width: 140px;
  height: 45px;
  background: url("~@/assets/loading_logo.png") no-repeat;
  position: absolute;
  top: 45%;
  left: 44%;
  background-size: contain;
}
#sidebar {
  position: fixed;
  z-index: 999;
  width: 230px;
  height: 100vh;
  background: #f7f5f5;
  float: left;
}
.sidebar__logo-wrapper {
  height: 61px;
  background: #f42f4b;
  padding: 10px 0 20px;
}
.sidebar__logo {
  width: 140px;
  height: 45px;
  background: url("~@/assets/logo.svg") no-repeat;
  margin: 3px auto;
  background-size: contain;
}
.sidebar__menu {
  padding: 15px 0 0 33px;
}
.sidebar__menu ul {
  padding-left: 0 !important;
}
.sidebar__menu ul a {
  display: block;
  color: #333 !important;
  font-size: 12px;
  margin-bottom: 11px;
}
.sidebar__menu .router-link-exact-active {
  color: #f42f4b !important;
  border-radius: 30px;
}
.admin-panel__content {
  display: flex;
  flex-direction: column;
  padding-left: 215px;
}

.admin-panel__content__header {
  background: #f7f5f5;
  height: 61px;
  padding: 7px 20px 0;
}
.admin-panel__content__header__messages {
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
  padding: 0 15px;
}
.admin-panel__content__header__messages i,
.admin-panel__content__header__config i {
  color: #777;
  font-size: 20px;
}
.admin-panel__content__header__messages .badge {
  margin-left: -11px;
}
#messagesCollapse {
  position: absolute;
  z-index: 999;
  width: 268px;
  height: 210px;
  background: #fff;
  text-align: left;
  border: 1px solid #ddd;
  box-shadow: 0 5px 15px rgb(0 0 0 / 14%);
  overflow-y: scroll;
  padding: 5px 12px;
  top: 41px;
  right: -95px;
}
.messagesCollapse__item {
  border-bottom: 1px solid #ddd;
  padding: 5px 0;
  margin: 2px 0;
}
.messagesCollapse__item:last-child {
  border-bottom: none;
  margin-bottom: 0;
}
.messagesCollapse__item__type {
  background: #888;
  color: #fff;
  border-radius: 4px;
  font-size: 14px;
  padding: 2px 5px;
  margin-right: 6px;
}

.breadcrumb {
  background-color: transparent !important;
}

h1 {
  font-size: 26px;
  margin-top: 8px;
}
</style>
