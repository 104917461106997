<template>
  <div class="w-100 pl-1">
    <h1>Tags Management</h1>

    <div id="items" class="row mt-3">
      <div
        class="item col-lg-3"
        v-for="item in items"
        v-bind:key="item.id"
        :style="getPhotoUrl(item)"
        @click="showUpdateModal(item)"
      >
        <div style="position: relative">
          <i v-if="item.related_attributes?.length > 0" class="alert alert-success">Active</i>
          <i v-else class="alert alert-danger">Deactivated</i>
        </div>
        <div class="item-overlay">
          {{ item.title }}<br />
<!--          <i class="fa fa-hashtag"></i> {{ item.slug }}-->
          <hr>
          <div>
            related to :
            <ul v-for="attribute in item.related_attributes" :key="attribute.id">
              <li>{{attribute.value}}</li>
            </ul>
          </div>
        </div>
      </div>

      <div class="item col-lg-3 add-item" @click="showCreateModal">
        <div class="text-center">
          <i class="fa fa-plus"></i><br />
          Create a tag
        </div>
      </div>
    </div>

    <modal name="create-modal" height="auto">
      <div class="p-4">
        <button @click="$modal.hide('create-modal')" class="modal-close-button">
          ❌
        </button>
        <div class="h3 mb-3">Create a tag</div>
        <form action="">
          <select
            v-model="createForm.type"
            class="form-control mb-3"
            @change="filterAttributes('create')"
          >
            <option value="occasion">Occasion</option>
            <option value="recipient">Recipient</option>
          </select>
          <input
            v-model="createForm.title"
            type="text"
            class="form-control"
            placeholder="Title"
            required
          />
<!--          <input-->
<!--            v-model="createForm.slug"-->
<!--            type="text"-->
<!--            class="form-control mt-3 mb-3"-->
<!--            placeholder="some-slug-here"-->
<!--            required-->
<!--          />-->
<!--          <textarea-->
<!--            v-model="createForm.description"-->
<!--            class="form-control"-->
<!--            placeholder="Description"-->
<!--          ></textarea>-->
<!--          <div class="mb-2 mt-3">-->
<!--            <label for="formFile" class="form-label">Select a photo...</label>-->
<!--            <input-->
<!--              class="form-control"-->
<!--              type="file"-->
<!--              id="formFile"-->
<!--              @change="createFormUpload"-->
<!--              ref="createFormUpload"-->
<!--            />-->
<!--          </div>-->
          <div v-if="this.createForm.photo">
            <i class="fa fa-check"></i> Photo uploaded!
          </div>
          <div class="form-group col-lg-12">
            <label>Related connect attributes</label>
            <multiselect
                class="form-control"
                v-model="selectedAttributes"
                :options="attributes"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                placeholder="Pick some"
                label="value"
                track-by="id"
                :preselect-first="false">
            </multiselect>
          </div>

          <ul style="padding-left: 20px; margin-top: 20px">
            <li
              v-for="error in createForm.errors"
              v-bind:key="error"
              class="text-danger"
            >
              {{ error }}
            </li>
          </ul>

          <button class="btn btn-primary mt-3 w-100" @click="createTag">
            Create
          </button>
        </form>
      </div>
    </modal>

    <modal name="update-modal" height="auto">
      <div class="p-4">
        <button @click="$modal.hide('update-modal')" class="modal-close-button">
          ❌
        </button>
        <div class="h3 mb-3">Update a tag</div>
        <form action="">
          <select
            v-model="updateForm.type"
            class="form-control mb-3"
            @change="filterAttributes('update')"
          >
            <option :selected = "updateForm.type === 'occasion'" value="occasion">Occasion</option>
            <option :selected = "updateForm.type === 'recipient'" value="recipient">Recipient</option>
          </select>
          <input
            v-model="updateForm.title"
            type="text"
            class="form-control"
            placeholder="Title"
            required
          />
<!--          <input-->
<!--            v-model="updateForm.slug"-->
<!--            type="text"-->
<!--            class="form-control mt-3 mb-3"-->
<!--            placeholder="some-slug-here"-->
<!--            required-->
<!--          />-->
<!--          <textarea-->
<!--            v-model="updateForm.description"-->
<!--            class="form-control"-->
<!--            placeholder="Description"-->
<!--          ></textarea>-->
<!--          <div class="mb-2 mt-3">-->
<!--            <label for="formFile" class="form-label">Select a photo...</label>-->
<!--            <input-->
<!--              class="form-control"-->
<!--              type="file"-->
<!--              id="formFile"-->
<!--              @change="updateFormUpload"-->
<!--              ref="updateFormUpload"-->
<!--            />-->
<!--          </div>-->
          <div v-if="this.updateForm.photo">
            <i class="fa fa-check"></i> Photo uploaded!
          </div>
          <div class="form-group col-lg-12">
            <label>Related connect attributes</label>
            <multiselect
                class="form-control"
                v-model="selectedAttributes"
                :options="attributes"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                placeholder="Pick some"
                label="value"
                track-by="id"
                :preselect-first="false">
            </multiselect>
          </div>

          <ul style="padding-left: 20px; margin-top: 20px">
            <li
              v-for="error in updateForm.errors"
              v-bind:key="error"
              class="text-danger"
            >
              {{ error }}
            </li>
          </ul>

          <button class="btn btn-primary mt-3 w-100" @click="updateTag">
            Update
          </button>
          <button class="btn btn-danger mt-2 w-100" @click="deleteTag">
            Delete
          </button>
        </form>
      </div>
    </modal>
  </div>
</template>

<script>
import { config } from "../helpers";
import multiselect from "vue-multiselect";

export default {
  name: "Tags",
  components: {multiselect},
  data() {
    return {
      url: "",
      items: [],
      attributes : [],
      selectedAttributes: [],
      createForm: {
        type: "",
        title: "",
        slug: "",
        description: "",
        photo: "",
        errors: [],
        attributes: [],
      },
      updateForm: {
        id: null,
        type: "",
        title: "",
        slug: "",
        description: "",
        photo: "",
        errors: [],
        attributes: [],
      },
    };
  },

  mounted() {
    this.url = config("BACKEND_ROOT");
    this.loadData();
    this.getAttributes();
  },

  methods: {
    loadData() {
      axios.get(this.url+"/admin/tags").then((response) => {
        this.items = response.data.data;
      });
    },

    getAttributes(){
      axios.get(this.url+"/admin/attributes").then((response) => {
        this.attributes = response.data.data;
      });
    },

    filterAttributes(action){
      let type = '';
      if(action === 'update'){
        type = this.updateForm.type;
      }
      else{
        type = this.createForm.type;
      }

      type = type.charAt(0).toUpperCase() + type.slice(1)+'-Label'

      this.attributes = this.attributes.filter(attr => attr.key === type)
      console.log(this.attributes);


    },

    createTag(event) {
      event.preventDefault();
      axios
        .post(this.url+"/admin/tags", {
          type: this.createForm.type,
          title: this.createForm.title,
          slug: this.createForm.slug,
          photo: this.createForm.photo,
          attributes : this.selectedAttributes.map(({ id, key, value }) => ({ 'id' :id, 'value': value }))
        })
        .then((response) => {
          this.loadData();
          this.$modal.hide("create-modal");
        })
        .catch((error) => {
          this.createForm.errors = Object.values(
            error.response.data.errors
          ).flat();
        });
    },

    updateTag(event) {
      this.updateForm.attributes = this.selectedAttributes.map(({ id, key, value }) => ({ 'id' :id, 'value': value }));

      event.preventDefault();
      axios
        .put(this.url+"/admin/tags" + "/" + this.updateForm.id, {
          type: this.updateForm.type,
          title: this.updateForm.title,
          slug: this.updateForm.slug,
          description: this.updateForm.description,
          photo: this.updateForm.photo,
          attributes: this.updateForm.attributes
        })
        .then((response) => {
          this.loadData();
          this.$modal.hide("update-modal");
        })
        .catch((error) => {
          this.updateForm.errors = Object.values(
            error.response.data.errors
          ).flat();
        });
    },

    deleteTag(event) {
      event.preventDefault();
      if (confirm("Do you want to delete this tag?")) {
        axios.delete(this.url+"/admin/tags" + "/" + this.updateForm.id).then((response) => {
          location.reload();
        });
      }
    },

    showCreateModal() {
      this.$modal.show("create-modal");
    },

    showUpdateModal(item) {
      this.updateForm = {
        id: null,
        type: null,
        title: "",
        slug: "",
        description: "",
        photo: "",
        errors: [],
        attributes: []
      };
      this.updateForm.id = item.id;
      this.updateForm.type = item.key;
      this.updateForm.title = item.title;
      this.updateForm.title = item.title;
      // this.updateForm.slug = item.slug;
      this.selectedAttributes = item.related_attributes;
      this.filterAttributes('update');
      this.$modal.show("update-modal");
    },

    createFormUpload() {
      const formData = new FormData();
      formData.append("file", this.$refs.createFormUpload.files[0]);
      formData.append("type", this.$refs.createFormUpload.files[0].type.split('/')[0]);
      const headers = { "Content-Type": "multipart/form-data" };
      axios
        .post(config("BACKEND_ROOT") + "/admin/uploads", formData, { headers })
        .then((res) => {
          this.createForm.photo = res.data.data.file_name;
        })
        .catch((error) => {
          this.createForm.errors = error.response.data.errors.file;
        });
    },

    updateFormUpload() {
      const formData = new FormData();
      formData.append("file", this.$refs.updateFormUpload.files[0]);
      formData.append("type", this.$refs.updateFormUpload.files[0].type.split('/')[0]);
      const headers = { "Content-Type": "multipart/form-data" };
      axios
        .post(config("BACKEND_ROOT") + "/admin/uploads", formData, { headers })
        .then((res) => {
          this.updateForm.photo = res.data.data.file_name;
        })
        .catch((error) => {
          this.updateForm.errors = error.response.data.errors.file;
        });
    },

    getPhotoUrl(item) {
      if (!item.photo) {
        return "";
      }

      return `background:url("${item.photo.url}")`;
    },
  },
};
</script>

<style scoped>
.item {
  background-size: cover !important;
  color: #fff;
  min-height: 100px;
  border-radius: 6px;
  margin: 5px !important;
  cursor: pointer;
}
.item-overlay {
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 15px 20px;
  transition: 0.3s !important;
}
.item-overlay:hover {
  background-color: rgba(0, 0, 0, 0.6);
}
.add-item {
  color: #666;
  border: 2px dashed #999;
  padding-top: 17px;
  transition: 0.3s !important;
}
.add-item:hover {
  color: #f42f4b;
  border-color: #f42f4b;
}
.alert{
  position: absolute;
  top: 0;
  right: 0;
}
ul{
  list-style-type: none;
}
li{
  line-height: 12px;
}
</style>