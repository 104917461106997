<template>
  <div class="w-100">
    <h1>Corporates</h1>

    <div class="row">
      <div class="col-lg-4 mb-3 mt-2">
        <input
          placeholder="Search..."
          v-model="inputFilters.q"
          class="form-control"
          v-on:keyup.enter="filter"
        />
      </div>
      <div class="col-lg-2" style="padding-top: 7px;">
        <button class="btn btn-primary" @click="filter">
          <i class="fas fa-search"></i>
        </button>
      </div>

      <div class="col-lg-6">
        <button class="btn btn-primary float-right ml-2" @click="$modal.show('create-modal')">Create</button>
        <button class="btn btn-success float-right" @click="exportToCSV()">Export</button>
      </div>
    </div>

    <div class="table-responsive">
      <table class="table mt-4">
        <thead class="thead-light">
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Company Name</th>
            <th scope="col">Email Address</th>
            <th scope="col">Phone</th>
            <th scope="col">Service Interested</th>
            <th scope="col">Budget</th>
            <th scope="col">Credit</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="corporate in corporates" v-bind:key="corporate.id">
            <td>{{ `${corporate.user.first_name} ${corporate.user.last_name}`.trim() }}</td>
            <td>{{ corporate.name }}</td>
            <td>{{ corporate.user.email }}</td>
            <td>{{ corporate.user.phone_number }}</td>
            <td>{{ corporate.services.join(', ') }}</td>
            <td>{{ corporate.budget }}</td>
            <td>{{ corporate.user.balance }}</td>
            <td>
              <b-dropdown text="" class="m-md-2" boundary="viewport">
                <b-dropdown-item @click.prevent="loginAsCorporate(corporate.user)">Login as the corporate</b-dropdown-item>
                <b-dropdown-item @click="$router.push(`/corporates/${corporate.id}/edit`)">Edit</b-dropdown-item>
                <b-dropdown-item @click="$router.push(`/users/${corporate.user.id}/purchases`)">View purchase history</b-dropdown-item>
                <b-dropdown-item @click="$router.push(`/users/${corporate.user.id}/wallet-history`)">View credit history</b-dropdown-item>
                <b-dropdown-item @click="deleteCorporate(corporate.id)">Delete</b-dropdown-item>
              </b-dropdown>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <nav>
      <ul class="pagination">
        <li
          class="page-item"
          v-bind:class="{ disabled: page == 1 }"
          @click="previousPage()"
        >
          <a class="page-link" href="#" tabindex="-1">Previous</a>
        </li>
        <li
          class="page-item"
          v-bind:class="{ disabled: page == lastPage }"
          @click="nextPage()"
        >
          <a class="page-link" href="#">Next</a>
        </li>
      </ul>
    </nav>

    <modal name="create-modal" height="auto">
      <div class="p-4">
        <button @click="$modal.hide('create-modal')" class="modal-close-button">❌</button>
        <div class="h3 mb-3">Create a corporate</div>
        <form action="https://google.com">
          <div class="form-row">
            <div class="form-group col-lg-6">
              <label for="firstnameInput">Firstname</label>
              <input
                type="text"
                v-model="createForm.user.first_name"
                class="form-control"
                id="firstnameInput"
                required
              />
            </div>
            <div class="form-group col-lg-6">
              <label for="lastnameInput">Lastname</label>
              <input
                v-model="createForm.user.last_name"
                class="form-control"
                id="lastnameInput"
                required
              />
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-lg-6">
              <label for="companyInput">Company name</label>
              <input
                v-model="createForm.name"
                class="form-control"
                id="companyInput"
                required
              />
            </div>
            <div class="form-group col-lg-6">
              <label for="websiteInput">Website url</label>
              <input
                v-model="createForm.website"
                class="form-control"
                id="websiteInput"
                required
              />
            </div>
          </div>

          <div class="form-row mt-2">
            <div class="form-group col-lg-6">
              <label for="emailInput">Email</label>
              <input
                v-model="createForm.user.email"
                type="email"
                class="form-control"
                id="emailInput"
                required
              />
            </div>
            <div class="form-group col-lg-6">
              <label for="phoneNumberInput">Phone number</label>
              <input
                v-model="createForm.user.phone_number"
                class="form-control"
                id="phoneNumberInput"
                required
              />
            </div>
          </div>

          <hr />

          <div class="form-row mt-3">
            <b-form-group label="Services:" v-slot="{ ariaServices }">
              <b-form-checkbox-group
                id="checkbox-group-1"
                v-model="createForm.services"
                :options="services"
                :aria-describedby="ariaServices"
                name="flavour-1"
              ></b-form-checkbox-group>
            </b-form-group>
          </div>

          <hr />

          <div class="form-row mt-3">
            <div class="form-group col-lg-6">
              <country-search-box @selected="(id) => createForm.user.address.country_id = id"></country-search-box>
            </div>
            <div class="form-group col-lg-6">
              <city-search-box @selected="(id) => createForm.user.address.city_id = id"></city-search-box>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-lg-8">
              <label for="addressInput">Address</label>
              <input
                v-model="createForm.user.address.address"
                class="form-control"
                id="addressInput"
                required
              />
            </div>
            <div class="form-group col-lg-4">
              <label for="postcodeInput">Postcode</label>
              <input
                v-model="createForm.user.address.post_code"
                class="form-control"
                id="postcodeInput"
              />
            </div>
          </div>

          <ul style="padding-left: 20px; margin-top: 20px">
            <li
              v-for="error in createForm.errors"
              v-bind:key="error"
              class="text-danger"
            >
              {{ error }}
            </li>
          </ul>

          <button
            type="submit"
            class="btn btn-primary mt-3 w-100"
            @click="createCorporate"
          >
            Create
          </button>
        </form>
      </div>
    </modal>
  </div>
</template>

<script>
import { config } from "../helpers";
import CountrySearchBox from "../components/CountrySearchBox.vue";
import CitySearchBox from "../components/CitySearchBox.vue";

export default {
  name: "Corporates",
    components: {
    "country-search-box": CountrySearchBox,
    "city-search-box": CitySearchBox,
  },
  data() {
    return {
      url: "",
      corporates: [],
      inputFilters: {
        q: null,
      },
      isFiltered: false,
      filters: {},
      page: 1,
      lastPage: 1,

      services: ["Rewards & Recognition", "Long Service Awards", "Motivation", "On Pack Promotions", "Corporate Gifts", "Team Building", "Sales Incentives", "Themed Incentives", "Corporate Hospitality", "Marketing Promotions", "Referral Schemes", "Employee Benefits", "Prizes"],
      createForm: { user: { first_name: "", address: { address: "" } } },
    };
  },

  mounted() {
    this.url = config("BACKEND_ROOT") + "/admin/corporates";
    this.loadData();
  },

  methods: {
    loadData() {
      let myData = { page: this.page, see: 1 };

      if (this.isFiltered) {
        let filters = this.filters;
        myData = { ...myData, ...filters };
      }

      axios.get(this.url, { params: myData }).then((response) => {
        this.corporates = response.data.data;
        this.page = response.data.meta.current_page;
        this.lastPage = response.data.meta.last_page;
      });
    },

    filter() {
      this.page = 1;
      this.isFiltered = true;
      let filters = {};

      if (this.inputFilters.q) {
        filters.q = this.inputFilters.q;
      }

      this.filters = filters;

      this.loadData();
    },

    createCorporate(event) {
      event.preventDefault();
      axios
        .post(this.url, this.createForm)
        .then((response) => {
          this.createForm = {};
          alert("Corporate created successfully!");
          this.$modal.hide("create-modal");
          this.loadData();
        })
        .catch((error) => {
          alert(Object.values(
            error.response.data.errors
          ).flat()[0])
        });
    },

    exportToCSV() {
      axios.get(this.url + "/export").then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "corporates.csv");
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
    },

    loginAsCorporate(user) {
      axios
          .post(`${config("BACKEND_ROOT")}/admin/users/${user.id}/login`)
          .then((response) => {
            const token = response.data.access_token;
            const refresh = response.data.refresh_token;
            const uuid = response.data.cart.uuid;
            const count = response.data.cart.items.length;

            window.open(`${config('FRONTEND_ROOT')}/auth/autologin?token=${token}&refresh=${refresh}&uuid=${uuid}&count=${count}&to=/dashboard`, '_blank').focus();
          });
    },

    nextPage() {
      if (this.page == this.lastPage) {
        return;
      }

      this.page++;
      this.loadData();
    },

    previousPage() {
      if (this.page == 1) {
        return;
      }

      this.page--;
      this.loadData();
    },

    deleteCorporate(id) {
      if (confirm('Do you want to delete this corporate?')) {
        axios
        .delete(`${this.url}/${id}`)
        .then((response) => {
          this.loadData();
        });
      }
    },

    formatDate(date) {
      date = new Date(date);
      return (
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1) +
        "-" +
        date.getDate() +
        " " +
        date.getHours() +
        ":" +
        date.getMinutes()
      );
    },
  },
};
</script>

<style scoped>
.table {
  font-size: 14px !important;
}
</style>