<template>
  <div class="p-4">
    <button @click="$modal.hide('confirm-modal')" class="modal-close-button">
      ❌
    </button>

    <div class="h3 mb-3">Confirm the cart</div>

    <div class="form">
      <div class="row">
        <div class="col-lg-12">
          <div class="form-check">
            <input
              v-model="balance_selected"
              class="form-check-input"
              type="checkbox"
              value="balance"
              id="confirm-modal__balance-option"
              :disabled="0 == this.cart.user.balance"
            />
            <label class="form-check-label" for="confirm-modal__balance-option">
              Balance (Current: {{ this.cart.user.balance }})
            </label>
          </div>
        </div>
      </div>

      <div class="row mt-2 mb-2">
        <div class="col-lg-12">
          <div class="form-check" v-for="method of available_methods" v-bind:key="method">
            <input
              v-model="form.payment_method"
              class="form-check-input"
              type="radio"
              name="exampleRadios"
              :id="'confirm-modal__' + method.replace(' ', '') + '-option'"
              :value="method"
            />
            <label
              class="form-check-label"
              :for="'confirm-modal__' + method.replace(' ', '') + '-option'"
            >
              {{ method.charAt(0).toUpperCase() + method.slice(1) }}
            </label>
          </div>
          <div style="border: gray 1px solid; padding: 5px; border-radius: 3px; margin-top: 10px" class="form-group col-lg-7">
            <label for="birthdateInput">Promotion code:</label>
            <input
                v-model="form.promo_code"
                type="text"
                class="form-control"
                id="birthdateInput"
            />
          </div>
        </div>
      </div>
    </div>

    <button
      class="btn btn-success mt-2 w-100"
      @click.prevent="submit()"
      :disabled="form.payment_method.length == 0 && balance_selected == false"
    >
      Confirm
    </button>
  </div>
</template>

<script>
import { config } from "../../../helpers";

export default {
  props: ["cart"],

  data() {
    return {
      available_methods: [
        "credit card",
        "cash",
        "paypal",
        "cheque",
        "bank transfer",
      ],

      form: {
        payment_method: "",
        promo_code: ""
      },

      balance_selected: false,
    };
  },

  methods: {
    submit() {
      let paymentMethod = [];

      if (this.form.payment_method) {
        paymentMethod.push(this.form.payment_method);
      }

      if (this.balance_selected) {
        paymentMethod.push('balance');
      }

      this.form.payment_method = "";
      this.balance_selected = false;

      axios
        .post(`${config("BACKEND_ROOT")}/admin/carts/${this.cart.id}/confirm`, {
          payment_method: paymentMethod,
          promo_code: this.form.promo_code
        })
        .then((response) => {
          this.$emit("done");
          alert("Cart was confirmed successfully!");
          this.$modal.hide("confirm-modal");
        })
        .catch((error) => {
          alert("Something bad happend!");
        });
    },
  },
};
</script>