<template>
  <div class="w-100">
    <h1 class="d-inline">Banners</h1>
    <button class="btn btn-success float-right" @click="showCreateModal">Create a banner</button>

    <div class="table-responsive">
      <table class="table mt-4">
        <thead class="thead-light">
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Position</th>
            <th scope="col">Title</th>
            <th scope="col">Photo</th>
            <th scope="col">Link</th>
            <th scope="col">Description</th>
            <th scope="col">Created at</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="banner in items" v-bind:key="banner.id">
            <td>{{ banner.id }}</td>
            <td>{{ banner.position }}</td>
            <td>{{ banner.meta.title || '-' }}</td>
            <td><img :src="banner.meta.url" @click="showPhotoModal(banner.photo.url)" class="rounded cursor-pointer" style="height:65px;"></td>
            <td><a :href="banner.url"><i class="fa fa-link h5"></i></a></td>
            <td>{{ banner.description }}</td>
            <td>{{ formatDate(banner.created_at) }}</td>
            <td>
              <button class="btn btn-sm btn-primary" title="Edit" @click="showUpdateModal(banner)">
                <i class="fa fa-edit"></i>
              </button>
              <button class="btn btn-sm btn-danger ml-2" title="Delete" @click="deleteBanner(banner)">
                <i class="fa fa-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <modal name="create-modal" height="auto">
      <div class="p-4">
        <button @click="$modal.hide('create-modal')" class="modal-close-button">❌</button>
        <div class="h3 mb-3">Create a banner</div>
        <form action="">
          <select
            v-model="createForm.position"
            class="custom-select"
            required
          >
            <option value="home-slider">home-slider</option>
            <option value="mobile-banner">mobile-banner</option>
          </select>
          <input
            v-model="createForm.description"
            type="text"
            class="form-control mt-3 mb-3"
            placeholder="Description, some alternative text"
            required
          />
          <input
            v-model="createForm.url"
            type="text"
            class="form-control"
            placeholder="https://example.com/your-url"
          >
          <hr>
          
          <file-upload label="Select a photo..." @type="setFileType" @done="file_name => this.createForm.photo = file_name" class="mt-3"></file-upload>

          <hr>

          <h6 class="mt-4">Meta values</h6>
          <key-value-input v-model="createForm.meta"></key-value-input>

          <ul style="padding-left: 20px; margin-top: 20px">
            <li
              v-for="error in createForm.errors"
              v-bind:key="error"
              class="text-danger"
            >
              {{ error }}
            </li>
          </ul>

          <button type="submit" class="btn btn-primary mt-3 w-100" @click.prevent="createBanner">
            Create
          </button>
        </form>
      </div>
    </modal>

    <modal name="update-modal" height="auto">
      <div class="p-4">
        <button @click="$modal.hide('update-modal')" class="modal-close-button">❌</button>
        <div class="h3 mb-3">Edit a banner</div>
        <form action="">
          <input
            v-model="updateForm.description"
            type="text"
            class="form-control mt-3 mb-3"
            placeholder="Description, some alternative text"
            required
          />
          <input
            v-model="updateForm.url"
            type="text"
            class="form-control"
            placeholder="https://example.com/your-url"
          >
          <hr>

          <file-upload label="Select a photo..." @type="setFileType" @done="file_name => this.updateForm.photo = file_name" :photo="updateForm.previousPhoto" class="mt-3"></file-upload>

          <hr>

          <h6 class="mt-4">Meta values</h6>
          <key-value-input v-model="updateForm.meta" :editData="updateForm.meta"></key-value-input>

          <ul style="padding-left: 20px; margin-top: 20px">
            <li
              v-for="error in updateForm.errors"
              v-bind:key="error"
              class="text-danger"
            >
              {{ error }}
            </li>
          </ul>

          <button type="submit" class="btn btn-primary mt-3 w-100" @click.prevent="updateBanner">
            Edit
          </button>
        </form>
      </div>
    </modal>

    <modal name="photo-modal" height="auto" :adaptive="true">
      <div class="p-4">
        <button @click="$modal.hide('photo-modal')" class="modal-close-button">❌</button>
        <div class="h3 mb-3">Banner photo</div>
        <img :src="photoModalCurrentUrl" class="w-100 mt-3">
      </div>
    </modal>
  </div>
</template>

<script>
import { config } from "../helpers";
import KeyValueInput from "../components/KeyValueInput.vue";
import FileUpload from '../components/FileUpload.vue';

export default {
  name: "Banners",
  components: {
    'key-value-input': KeyValueInput,
    'file-upload': FileUpload,
  },
  data() {
    return {
      url: "",
      items: [],

      type: '',
      createForm: {
        position: '',
        description: '',
        photo: '',
        url: '',
        meta: {},
        errors: [],
      },

      updateForm: {
        description: '',
        previousPhoto: '',
        photo: '',
        url: '',
        meta: {},
        errors: [],
      },

      photoModalCurrentUrl: '',
    };
  },

  mounted() {
    this.url = config("BACKEND_ROOT") + "/admin/banners";
    this.loadData();
  },

  methods: {
    loadData() {
      axios.get(this.url).then((response) => {
        this.items = response.data.data;
      });
    },

    showCreateModal() {
      this.createForm = { position: '', description: '', photo: '', url: '', meta: {}, errors: [] };
      this.$modal.show("create-modal");
    },

    setFileType(val){
      this.type = val;
    },

    createBanner(e) {
      e.target.innerText = "Creating...";
      e.target.disabled = true;

      axios
        .post(this.url, {
          position: this.createForm.position,
          description: this.createForm.description,
          url: this.createForm.url,
          meta: this.createForm.meta,
          photo: this.createForm.photo,
          type: this.type[0],
        })
        .then((response) => {
          this.loadData();
          this.$modal.hide("create-modal");
        })
        .catch((error) => {
          e.target.innerText = "Create";
          e.target.disabled = false;

          this.createForm.errors = Object.values(
            error.response.data.errors
          ).flat();
        });
    },

    showUpdateModal(banner) {
      this.updateForm = { description: '', previousPhoto: '', photo: '', url: '', meta: {}, errors: [] };
      this.updateForm.id = banner.id;
      this.updateForm.description = banner.description;
      this.updateForm.url = banner.url;
      this.updateForm.meta = banner.meta;
      this.updateForm.previousPhoto = banner.photo?.url;
      this.$modal.show("update-modal");
    },

    updateBanner(e) {
      e.target.innerText = "Editing...";
      e.target.disabled = true;

      axios
        .put(`${this.url}/${this.updateForm.id}`, {
          description: this.updateForm.description,
          url: this.updateForm.url,
          meta: this.updateForm.meta,
          photo: this.updateForm.photo,
          type: this.type[0],
        })
        .then((response) => {
          this.$modal.hide("update-modal");
          this.loadData();
        })
        .catch((error) => {
          e.target.innerText = "Edit";
          e.target.disabled = false;

          this.updateForm.errors = Object.values(
            error.response.data.errors
          ).flat();
        });
    },

    deleteBanner(banner) {
      if (confirm("Are you sure about this?!")) {
        axios
        .delete(`${this.url}/${banner.id}`)
        .then((response) => {
          this.loadData();
        })
        .catch((error) => {
          alert('Something went wrong!');
        });
      }
    },

    showPhotoModal(photoUrl) {
      this.photoModalCurrentUrl = photoUrl;
      this.$modal.show('photo-modal');
    },

    formatDate(date) {
      if (! date) return null;

      date = new Date(date);
      
      return (
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1) +
        "-" +
        date.getDate() +
        " " +
        date.getHours() +
        ":" +
        date.getMinutes()
      );
    },
  },
};
</script>

<style scoped>
.table {
  font-size: 14px !important;
}
</style>