<template>
  <div>
    <div class="mb-2 mt-3">
      <label for="formFile" class="form-label">{{ label }}</label>
      <input
        class="form-control"
        type="file"
        id="formFile"
        @change="upload"
        ref="uploader"
      />
    </div>

    <div class="progress" v-if="fileSelected">
      <div
        :class="'progress-bar progress-bar-striped ' + progressClass"
        role="progressbar"
        :style="'width: ' + progressValue + '%'"
        :aria-valuenow="progressValue"
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>

    <div class="uploaded-image" v-if="photo">
      <img :src="photo" />
    </div>
  </div>
</template>

<script>
import { config } from "../helpers";

export default {
  props: ["label", "photo"],

  data() {
    return {
      selectedFile: null,
      fileName: null,

      fileSelected: false,
      progressClass: "",
      progressValue: 20,
    };
  },

  mounted() {},

  methods: {
    upload() {
      this.selectedFile = this.$refs.uploader.files[0];

      const type = this.selectedFile.type.split('/');

      this.photo = null;
      this.fileSelected = true;
      this.progressClass = "";
      this.progressValue = 20;

      const formData = new FormData();
      formData.append("file", this.selectedFile);
      formData.append("type", type[0]);

      const headers = { "Content-Type": "multipart/form-data" };

      axios
        .post(config("BACKEND_ROOT") + "/admin/uploads", formData, { headers })
        .then((res) => {
          this.fileName = res.data.data.file_name;
          this.$emit("done", this.fileName);
          this.$emit("type", type);

          this.progressClass = "bg-success";
          this.progressValue = 100;
        })
        .catch((error) => {
          this.progressClass = "bg-danger";
          this.progressValue = 100;

          alert(error.response.data.errors.file[0]);
        });
    },
  },
};
</script>

<style scoped>
.uploaded-image {
  width: 150px;
  border-radius: 4px;
  overflow: hidden;
  margin-top: 10px;
}
.uploaded-image img {
  width: 100%;
}
</style>