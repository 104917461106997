<template>
  <div class="w-100">
    <div class="row mt-4">
    <div class="col-lg-12 mb-3" style="margin-top: 1px;">
      <h1 class="float-left">Meta data</h1>
      <button
        class="btn btn-success float-right"
        @click="$router.push(`/meta-data/create`)"
      >
        Create meta data
      </button>
    </div>


      <div class="col-lg-3 mb-3" style="margin-top: 1px;">
        <label for="">Type:</label>
          <select
              v-model="inputFilters.type"
              class="form-control"
          >
            <option
                v-for="type in subjectTypes"
                :value="type.title"
                v-bind:key="type.title"
            >
              {{ type.title }}
            </option>
          </select>
      </div>
      <div class="col-lg-3 mb-3" style="margin-top: 1px;">
        <label>By title or description of meta </label>
        <input
            placeholder="Search..."
            v-model="inputFilters.q"
            class="form-control"
            v-on:keyup.enter="filter"
        />
      </div>
      <div class="col-lg-2">
        <label class="d-block" style="visibility: hidden">Filter</label>
        <button class="btn btn-primary" @click="filter">
          <i class="fas fa-search"></i>
        </button>
      </div>
    </div>

    <div class="table-responsive">
      <table class="table mt-4">
        <thead class="thead-light">
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Type</th>
            <th scope="col">Title</th>
            <th scope="col">Description</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" v-bind:key="item.id">
            <td>{{ item.id }}</td>
            <td>{{ item.subject_type }}</td>
            <td>{{ item.title }}</td>
            <td>{{ item.description }}</td>
            <td>
              <div
                class="btn btn-sm btn-primary"
                @click="
                  $router.push(
                    `/meta-data/${item.subject_type}/${item.subject_id}/edit`
                  )
                "
              >
                Edit
              </div>
              <div class="btn btn-sm btn-danger ml-2" @click="deleteItem(item)">
                Delete
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <nav>
      <ul class="pagination">
        <li
          class="page-item"
          v-bind:class="{ disabled: page == 1 }"
          @click="previousPage()"
        >
          <a class="page-link" href="#" tabindex="-1">Previous</a>
        </li>
        <li
          class="page-item"
          v-bind:class="{ disabled: page == lastPage }"
          @click="nextPage()"
        >
          <a class="page-link" href="#">Next</a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { config } from "../../../helpers";
import metadataOptions from "@/static/metadata.json";

export default {
  name: "MetaData",
  data() {
    return {
      url: `${config("BACKEND_ROOT")}/admin/meta-data`,

      items: [],
      subjectTypes: metadataOptions,

      page: 1,
      lastPage: 1,
      inputFilters: {
        q: "",
        type: "",
      },
      filters: {},
      isFiltered: false
    };
  },

  mounted() {
    this.loadItems();
  },

  methods: {
    loadItems() {
      let myData = { page: this.page };

      if (this.isFiltered) {
        myData = { ...myData, type: this.filters.type, q: this.filters.q };
      }

      axios.get(this.url, { params: myData }).then((response) => {
        this.items = response.data.data;
        this.page = response.data.meta.current_page;
        this.lastPage = response.data.meta.last_page;
      });
    },

    deleteItem(item) {
      if (!confirm("Do you want to delete this item?")) {
        return;
      }

      axios
        .delete(this.url + `/${item.subject_type}/${item.subject_id}`)
        .then((response) => {
          this.loadItems();
        });
    },

    nextPage() {
      if (this.page == this.lastPage) {
        return;
      }

      this.page++;
      this.loadItems();
    },

    previousPage() {
      if (this.page == 1) {
        return;
      }

      this.page--;
      this.loadItems();
    },

    filter() {
      this.page = 1;
      this.isFiltered = true;
      let filters = {};


      if (this.inputFilters.q) {
        filters.q = this.inputFilters.q;
      }
      if (this.inputFilters.type) {
        filters.type = this.inputFilters.type;
      }

      this.filters = filters;

      this.loadItems();
    },
  },
};
</script>

<style scoped>
.table {
  font-size: 14px !important;
}
</style>