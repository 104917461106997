import { render, staticRenderFns } from "./MetaDataEdit.vue?vue&type=template&id=a96e16e2&"
import script from "./MetaDataEdit.vue?vue&type=script&lang=js&"
export * from "./MetaDataEdit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports